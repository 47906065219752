import styled from "styled-components";

const Layout = styled.div`
  width: 100%;
  margin: 10px 0px;
  justify-content: space-between;
  @media (max-width: 600px){
    padding: 0px;
  }
  
  .documentContainer {
      margin: 40px 0;
    .documentModuleContainer {
        .documentsHeader {
            color: rgb(1, 105, 178);
            border-bottom: 3px solid rgb(1, 105, 178);
            font-family: Rubik-Medium;
            padding: 15px 10px;
            width: fit-content;
            margin-bottom: 15px;
            margin-right: 25px;
        }
        .documentSubContainer {
            @media (max-width: 600px){
               flex-wrap: wrap;
               justify-content: center;
            }
            display: flex;
            .documentContainerHolder {
                @media (max-width: 600px){
                       margin-bottom: 30px;
                }
                .downloadButtonContainer {
                    display: flex;
                    justify-content: center;
                    .downloadButton {
                        width: 150px;
                        text-align: center;
                        padding: 10px 20px;
                        border: 1px solid rgb(49, 113, 180);
                        border-radius: 20px;
                        background: rgb(49, 113, 180);
                        color: white;
                        cursor: pointer;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }
                display: flex;
                flex-direction: column;
                .documentMainContainer {
                    cursor: pointer;
                    @media (max-width: 600px){
                       margin-right: 0;
                       margin-bottom: 20px;
                    }
                    width: 300px;
                    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 21px;
                    background-color: rgb(255, 255, 255);
                    position: relative;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 20px 0;
                    margin-bottom: 20px;
                    .documentImage {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        padding: 10px 0;
                    img {
                        width: 100%
                        max-width: 250px
                    }
                }
                
                
            }
            
            .documentText {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                height: 100%;
                .titleText       {
                    font-size: 14px;
                    color: black;
                    font-weight: bold;
                }
                
                .titleLink {
                    margin-top: 10px;
                    color: rgb(49, 113, 180);
                    font-size: 14px;
                    font-weight: bold;
                    cursor: pointer;
                    i {
                        margin-left: 5px;
                    }
                }
            }
            }
        }
    }
  }
`;

const RewardTablesContainer = styled.div`
  width: 100%;
    .no-progress {
      text-align: center;
      background: white;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  @media (max-width: 600px) {
    overflow: hidden;
    overflow-x: scroll;  
    white-space: nowrap;
  }
  @media (max-width: 330px) {
    height: 220px;
  }
  .padding {  
    padding: 15px;
  }
  .tableBody {
    @media (max-width: 600px) {
          width: 100em;
    }
    background-color: rgba(0, 47, 71, 0.9);
    //background-color: ${({ background }) =>
    background ? "rgba(0, 47, 71, 0.9)" : "red"};
    .tableHeader {
        font-family: 'Rubik-Bold';
        font-size: 15px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: 50px;
        .headerLeft {
            .tableHeaderIcon {
                margin-right: 20px;
                img {
                    width: 25px;
                }
            }
        }
    }
    .tableContentContainer {
        width: 100%;
        height: 74px;
        display: flex;
        background: #FFFFFF;
       box-shadow: 13px 12px 10px rgba(0, 0, 0, 0.08);
         border-radius: 4px;
        margin-bottom: 30px;
       margin-top: 13px;

       .subContainer {
        width: 70%;
        padding: 15px 15px 15px 10px;
        .tableColumnHeader {
            display: flex;
            justify-content: space-between;
            .tableColumn {
              font-family: 'Rubik';
             font-style: normal;
             font-weight: 400;
              font-size: 14px;
             line-height: 17px;
              color: #0D4270;
               display: flex;
                flex-basis: 18%;
                margin-bottom: 15px;
                &.flex-basis-20 {
                  flex-basis: 20% !important;
                }
            }
            .tableIcon {
                flex-basis: 10%;
                display: flex;
                justify-content: center;
                cursor: pointer;
                .fa.fa-check-circle {
                    color: green;
                    font-size: 20px;
                }
                .fa.fa-times {
                    color: red;
                    font-size: 20px;
                }
            }
        }
        .tableRowContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tableRow {
              font-family: 'Rubik-Medium';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: #0D4270;
               display: flex;
               flex-basis: 18%;
               &:nth-child(3) {
                font-family: 'Rubik-Medium';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #0D4270;
                 display: flex;
               }
               &.flex-basis-30 {
                  flex-basis: 30% !important;
                }
            }
            .tableIcon {
                flex-basis: 10%;
                display: flex;
                justify-content: center;
                cursor: pointer;
                .fa.fa-check-circle {
                    color: green;
                    font-size: 20px;
                }
                .fa.fa-times {
                    color: red;
                    font-size: 20px;
                }
            }
        }
      }
      .background_container {
        width: 30%;
        display: flex;
        justify-content: flex-end;
           .bg_tick_mark {
            width: 37px;
            height: 74px;
            background: #9FC989;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
    }
    
  }
`;

const NewRewardTablesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
  .tableBody {
    width: 100%;
    background-color: ${({ checked }) =>checked ? "#006FBC" : " rgba(0, 47, 71, 0.9)"};
    border-radius: 6px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .tableHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding: 15px;
        .headerLeft {
            >span {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
            }
        }
        .headerRight {
            >span {
                font-family: 'Rubik';
                font-style: normal;
                 font-weight: 500;
                 font-size: 18px;
                 line-height: 21px;
                 color: #FFFFFF;
            }
        }
    }
    .tableContentContainer {
    height: 74px;
    width: 97%;
    margin: auto;
    margin-bottom: 25px
    justify-content: space-between;
    flex-wrap: wrap;
    background: #FFFFFF;
    box-shadow: 13px 12px 10px rgb(0 0 0 / 8%);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 10px;
      .childContainer {
        flex-direction: column;
        .tableColumnHeader {
          display: flex;
         justify-content: space-between;
          width: 100%;
          padding-bottom: 10px;
            .tableColumn {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px; 
                 color: #0D4270;
                 &.flex-basis-20 {
                    flex-basis: 20% !important;
                  }
            }
        }
        .tableRowContainer {
            display: flex;
            justify-content: space-between;
            width: 100%
            .tableRow {
                font-family: 'Rubik-Medium';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                 color: #0D4270;
                 &.flex-basis-20 {
                    flex-basis: 20% !important;
                  }
            }
        }
    }
      .background_container {
        display: flex;
        justify-content: flex-end;
        margin-right: -10px;
        margin-top: -10px;
           .bg_tick_mark {
            width: 37px;
            height: 74px;
            background: #9FC989;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
    }
  }
`;

const Circle = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 16px;
  border: ${({ checked }) =>
    checked ? "3px solid #96C26E" : "3px solid #FFFFFF"};
  background-color: ${({ checked }) => (checked ? "#96C26E" : "#FFFFFF")};
`;

const Tick = styled.div`
  content: "";
  position: relative;
  display: block;
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  border-style: solid;
  border-width: 0 2.4px 2.4px 0;
  border-color: ${({ checked }) => (checked ? checked : checked)};
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-color: transparent;
  border-left-color: transparent;
`;

const DocumentsContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  .titleName {
    width: 100px;
    padding-bottom: 15px;
    font-family: "Rubik-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0D4270;
  }

  .main_document {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
    .documents_data {
      display: flex;
      border-radius: 6px;
      width: calc((100%) / 4);
      height: 414px;
      .image_class {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        > img {
          border-radius: 4px;
          width: 100%;
          height: 100%;
        }

        .image_background {
          position: relative;
          .educateImageBackground {
            position: relative;
            top: 0;
            width: 100%;
            height: 414px;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px 6px 6px 6px;
            .getStarted {
              font-family: Rubik;
              font-style: Medium;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              width: 210px;
              height: 50px;
              background-color: #69c2ff;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 6px;
              color: white;
              text-align: center;
            }
          }
        }
      }
      .bg_download {
        .download {
        }
      }
    }
  }
`;
const BodyTable = styled.div`
  background-color: ${({ bgcolorOne }) => (bgcolorOne ? bgcolorOne : "rgba(0, 47, 71, 0.9)")};
  margin-bottom: 25px;
  padding-bottom: 6px;
  border-radius: 6px;
  .tableHeader 
  {
    margin-top: ${({isCompanyRewards})=>(isCompanyRewards ? "150px": "")};
    font-family: 'Rubik-Bold';
    font-size: 15px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-top: 15px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 15px;
    .headerLeft {
        .tableHeaderIcon {
            margin-right: 20px;
            img {
                width: 25px;
            }
        }
    }
}
.tableContentContainer {
    height: 80px;
    align-items:center;
    display: flex;
    background: #FFFFFF;
   box-shadow: 13px 12px 10px rgba(0, 0, 0, 0.08);
     border-radius: 4px;
    margin-bottom: 15px;
    margin-left: 18px;
    margin-left: 23px;
    margin-right: 23px;

   .subContainer {
    width: 100%;
    padding: 15px 15px 15px 10px;
    .tableColumnHeader {
        display: flex;
        justify-content: space-between;
        .tableColumn {
          font-family: 'Rubik';
         font-style: normal;
         font-weight: 400;
          font-size: 14px;
         line-height: 17px;
          color: #0D4270;
           display: flex;
            flex-basis: 18%;
            margin-bottom: 8px;
            &.flex-basis-30 {
              flex-basis: 30% !important;
            }
        }
        .tableIcon {
            flex-basis: 10%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            .fa.fa-check-circle {
                color: green;
                font-size: 20px;
            }
            .fa.fa-times {
                color: red;
                font-size: 20px;
            }
        }
    }
    .tableRowContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tableRow {
          font-family: 'Rubik-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #0D4270;
           display: flex;
           flex-basis: 18%;
           &:nth-child(3) {
            font-family: 'Rubik-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #0D4270;
             display: flex;
           }
           &.flex-basis-30 {
            flex-basis: 30% !important;
            }
        }
        .tableIcon {
            flex-basis: 10%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            .fa.fa-check-circle {
                color: green;
                font-size: 20px;
            }
            .fa.fa-times {
                color: red;
                font-size: 20px;
            }
        }
    }
  }
  .background_container {
    width: 15%;
    display: flex;
    justify-content: flex-end;
       .bg_tick_mark {
        width: 37px;
        height: 74px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
}

`;

const TickMark = styled.div`
  width: 37px;
  height: 74px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : bgColor)};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageTag = styled.div`
  border-radius: 6px;
  width: 100%;
  height: 100%;
  > img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }

  .image_background {
    position: relative;
    top: -414px;
    .educateImageBackground {
      position: relative;
      top: 0;
      width: 100%;
      height: 414px;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px 6px 6px 6px;
      .getStarted {
        font-family: Rubik;
        font-style: Medium;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width: 210px;
        height: 50px;
        background-color: #69c2ff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 6px;
        color: white;
        text-align: center;
      }
    }
  }
`;

const MainImage = styled.div`
  display: flex;
  border-radius: 6px;
  width: calc((100% - 78px) / 4);
  height: 414px;
  margin-left: ${({ marginRight }) =>
    marginRight ? marginRight : marginRight};
`;

export {
  Layout,
  RewardTablesContainer,
  NewRewardTablesContainer,
  Circle,
  Tick,
  DocumentsContainer,
  BodyTable,
  TickMark,
  ImageTag,
  MainImage,
};
